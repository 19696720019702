<template>
  <div class="admin-driver-licence-settings v-step-driver-license-section">
    <div class="title">
      <h1>{{ $t('admin.driver-licence-settings.title') }}</h1>
    </div>
    <div class="content">
      <div class="flexbox">
        <div class="left col-pc-6">
          <div class="subtitle">
            <h3>{{ $t('admin.driver-licence-settings.classes') }}</h3>
          </div>
          <div class="boxes">
            <ul class="col-pc-12 ul-boxes">
              <li v-if="communityDriverLicenseClasses.length === 0">
                <h4 class="uk-text-left uk-text-uppercase">{{ $t('admin.driver-licence-settings.no-classes') }}</h4>
              </li>
              <li
                v-for="licenseClass in communityDriverLicenseClasses"
                :key="licenseClass.id"
                class="classes-box col-mobile-12 col-pc-12"
              >
                <div class="li-content col-all-12">
                  <div class="name col-all-9">
                    <div class="text col-pc-12">
                      <h2 :title="licenseClass.name">{{ licenseClass.name }}</h2>
                      <div class="description-box" v-if="licenseClass.description">
                        <span>(</span>
                        <p class="description-text" :title="licenseClass.description">{{ licenseClass.description }}</p>
                        <span>)</span>
                      </div>
                    </div>
                  </div>
                  <div class="buttons col-all-3">
                    <button class="danger-full col-mobile-12" @click="deleteLicenseClass(licenseClass.id)">
                      <i class="fas fa-trash-alt"></i>
                    </button>
                  </div>
                </div>
                <div class="classes-dropdown col-f">
                  <div class="row col-pc-6 col-mobile-11">
                    <label for="status-code-text">{{ $t('admin.driver-licence-settings.class-text') }}</label>
                    <CustomInput
                      style="height: 35px;"
                      class="custom-input primary col-pc-12 col-mobile-12"
                      inputClasses="box custom-height primary col-pc-12 col-mobile-12"
                      type="text"
                      name="driver-licence-class-text"
                      :placeholder="$t('admin.driver-licence-settings.class-text_placeholder')"
                      v-model="licenseClass.name"
                      @combinedInput="updateLicenseClass(licenseClass, $event)"
                    ></CustomInput>
                  </div>
                  <div class="row col-pc-6 col-mobile-11">
                    <label for="status-code-type">{{ $t('admin.driver-licence-settings.class-description') }}</label>
                    <CustomInput
                      style="height: 35px;"
                      class="custom-input primary col-pc-12 col-mobile-12"
                      inputClasses="box custom-height primary col-pc-12 col-mobile-12"
                      type="text"
                      name="driver-licence-class-description"
                      :placeholder="$t('admin.driver-licence-settings.class-description_placeholder')"
                      v-model="licenseClass.description"
                      @input="updateLicenseClass(licenseClass)"
                    ></CustomInput>
                  </div>
                </div>
              </li>
              <li class="add" @click="addLicenseClass">
                <div class="_box">
                  <div class="icon">
                    <i class="fas fa-plus"></i>
                  </div>
                  <div class="text">
                    <span>{{ $t('admin.driver-licence-settings.add-a-class') }}</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="right col-pc-6">
          <div class="subtitle">
            <h3>{{ $t('admin.driver-licence-settings.conditions') }}</h3>
          </div>
          <div class="boxes">
            <ul class="col-pc-12 ul-boxes">
              <li v-if="communityDriverLicenseConditions.length === 0">
                <h4 class="uk-text-left uk-text-uppercase">{{ $t('admin.driver-licence-settings.no-conditions') }}</h4>
              </li>
              <li
                v-for="licenseCondition in communityDriverLicenseConditions"
                :key="licenseCondition.id"
                class="classes-box col-mobile-12 col-pc-12"
              >
                <div class="li-content col-all-12">
                  <div class="name col-pc-9 col-mobile-12">
                    <div class="text col-pc-12">
                      <h2 :title="licenseCondition.name">{{ licenseCondition.name }}</h2>
                      <div class="description-box" v-if="licenseCondition.description">
                        <span>(</span>
                        <p class="description-text" :title="licenseCondition.description">
                          {{ licenseCondition.description }}
                        </p>
                        <span>)</span>
                      </div>
                    </div>
                  </div>
                  <div class="buttons col-pc-3 col-mobile-12">
                    <button class="danger-full col-mobile-12" @click="deleteLicenseCondition(licenseCondition.id)">
                      <i class="fas fa-trash-alt"></i>
                    </button>
                  </div>
                </div>
                <div class="classes-dropdown col-f">
                  <div class="row col-pc-6 col-mobile-11">
                    <label for="status-code-text">{{ $t('admin.driver-licence-settings.condition-text') }}</label>
                    <CustomInput
                      style="height: 35px;"
                      class="custom-input primary col-pc-12 col-mobile-12"
                      inputClasses="box custom-height primary col-pc-12 col-mobile-12"
                      type="text"
                      name="driver-licence-condition-text"
                      :placeholder="$t('admin.driver-licence-settings.condition-text_placeholder')"
                      v-model="licenseCondition.name"
                      @combinedInput="updateLicenseCondition(licenseCondition, $event)"
                    ></CustomInput>
                  </div>
                  <div class="row col-pc-6 col-mobile-11">
                    <label for="status-code-type">
                      {{ $t('admin.driver-licence-settings.condition-description') }}
                    </label>
                    <CustomInput
                      style="height: 35px;"
                      class="custom-input primary col-pc-12 col-mobile-12"
                      inputClasses="box custom-height primary col-pc-12 col-mobile-12"
                      type="text"
                      name="driver-licence-condition-description"
                      :placeholder="$t('admin.driver-licence-settings.condition-description_placeholder')"
                      v-model="licenseCondition.description"
                      @input="updateLicenseCondition(licenseCondition)"
                    ></CustomInput>
                  </div>
                </div>
              </li>
              <li class="add" @click="addLicenseCondition">
                <div class="_box">
                  <div class="icon">
                    <i class="fas fa-plus"></i>
                  </div>
                  <div class="text">
                    <span>{{ $t('admin.driver-licence-settings.add-a-condition') }}</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped src="@/assets/less/dashboards/user-interfaces.less"></style>

<script>
export default {
  name: 'driver-licence',
  data() {
    return {
      communityDriverLicenseClasses: [],
      communityDriverLicenseConditions: []
    }
  },
  apollo: {
    communityDriverLicenseClasses: {
      query: require('@/graphql/admin/fetchDriverLicenseClasses.gql'),
      variables() {
        return {
          communityId: this.$store.state.selection.community.community.id
        }
      },
      subscribeToMore: {
        document: require('@/graphql/admin/subscribeToDriverLicenseClass.gql'),
        variables() {
          return {
            communityId: this.$store.state.selection.community.community.id
          }
        },
        updateQuery: (previousResult, { subscriptionData }) => {
          const licenseClass = subscriptionData.data.communityDriverLicenseClass

          if (licenseClass.mutation === 'CREATED') {
            return {
              communityDriverLicenseClasses: [...previousResult.communityDriverLicenseClasses, licenseClass.node]
            }
          }

          if (licenseClass.mutation === 'UPDATED') {
            return {
              communityDriverLicenseClasses: previousResult.communityDriverLicenseClasses.map(theLicenseClass =>
                theLicenseClass.id === licenseClass.node.id ? licenseClass.node : theLicenseClass
              )
            }
          }

          if (licenseClass.mutation === 'DELETED') {
            return {
              communityDriverLicenseClasses: previousResult.communityDriverLicenseClasses.filter(
                theLicenseClass => theLicenseClass.id !== licenseClass.previousValues.id
              )
            }
          }
        }
      }
    },
    communityDriverLicenseConditions: {
      query: require('@/graphql/admin/fetchDriverLicenseConditions.gql'),
      variables() {
        return {
          communityId: this.$store.state.selection.community.community.id
        }
      },
      subscribeToMore: {
        document: require('@/graphql/admin/subscribeToDriverLicenseCondition.gql'),
        variables() {
          return {
            communityId: this.$store.state.selection.community.community.id
          }
        },
        updateQuery: (previousResult, { subscriptionData }) => {
          const licenseCondition = subscriptionData.data.communityDriverLicenseCondition

          if (licenseCondition.mutation === 'CREATED') {
            return {
              communityDriverLicenseConditions: [
                ...previousResult.communityDriverLicenseConditions,
                licenseCondition.node
              ]
            }
          }

          if (licenseCondition.mutation === 'UPDATED') {
            return {
              communityDriverLicenseConditions: previousResult.communityDriverLicenseConditions.map(
                theLicenseCondition =>
                  theLicenseCondition.id === licenseCondition.node.id ? licenseCondition.node : theLicenseCondition
              )
            }
          }

          if (licenseCondition.mutation === 'DELETED') {
            return {
              communityDriverLicenseConditions: previousResult.communityDriverLicenseConditions.filter(
                theLicenseCondition => theLicenseCondition.id !== licenseCondition.previousValues.id
              )
            }
          }
        }
      }
    }
  },
  methods: {
    addLicenseClass() {
      this.$store.dispatch('admin/updateLicenseClass', { create: { name: '4' } })
    },
    addLicenseCondition() {
      this.$store.dispatch('admin/updateLicenseCondition', { create: { name: 'A' } })
    },
    updateLicenseClass(licenseClass, combinedInput) {
      if (licenseClass.name.length === 0) {
        this.$store.dispatch('errorManager/showError', { error: 'FIELD_CANNOT_BE_EMPTY' })
        if (combinedInput) {
          licenseClass.name = combinedInput.oldValue
        }
        return
      }

      const updatedLicenseClass = Object.assign({}, licenseClass)
      delete updatedLicenseClass.__typename
      delete updatedLicenseClass.id

      this.$store
        .dispatch('admin/updateLicenseClass', {
          update: { where: { id: licenseClass.id }, data: updatedLicenseClass }
        })
        .then(() => {
          this.$notify({
            type: 'success',
            title: this.$t('notifications.save.title'),
            text: this.$t('notifications.save.text')
          })
        })
        .catch(error => {
          this.$store.dispatch('errorManager/showError', { error: error })
        })
    },
    updateLicenseCondition(licenseCondition, combinedInput) {
      if (licenseCondition.name.length === 0) {
        this.$store.dispatch('errorManager/showError', { error: 'FIELD_CANNOT_BE_EMPTY' })
        if (combinedInput) {
          licenseCondition.name = combinedInput.oldValue
        }
        return
      }

      const updatedLicenseCondition = Object.assign({}, licenseCondition)
      delete updatedLicenseCondition.__typename
      delete updatedLicenseCondition.id

      this.$store
        .dispatch('admin/updateLicenseCondition', {
          update: { where: { id: licenseCondition.id }, data: updatedLicenseCondition }
        })
        .then(() => {
          this.$notify({
            type: 'success',
            title: this.$t('notifications.save.title'),
            text: this.$t('notifications.save.text')
          })
        })
        .catch(error => {
          this.$store.dispatch('errorManager/showError', { error: error })
        })
    },
    deleteLicenseClass(licenseClassId) {
      this.$store.dispatch('admin/deleteLicenseClass', licenseClassId)
    },
    deleteLicenseCondition(licenseConditionId) {
      this.$store.dispatch('admin/deleteLicenseCondition', licenseConditionId)
    }
  }
}
</script>
